/* General App Styling */
body {
  margin: 0;
  padding: 0;
  background-color: #282c34; /* Unified background color */
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}

/* Header Styling */
.App-header {
  width: 100%; /* Full-width header */
  padding: 20px 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 700; /* Bold font for header */
  font-size: 2.5em;
  color: white;
  text-align: center;
  background-color: #282c34;
}

/* Main Content Area */
.App-main {
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures all child elements are perfectly centered */
  justify-content: flex-start;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
}

/* Week Text Styling */
.week-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5em; /* Slightly smaller than header */
  margin-bottom: 20px; /* Space between week text and ice cards */
  text-align: center;
  width: 90%; /* Match the width of ice cards */
  max-width: 600px;
  margin: 0 auto; /* Center horizontally */
  box-sizing: border-box; /* Ensure padding is included in total width */
  user-select: none;
  cursor: pointer; /* Changes cursor to pointer on hover */

}

/* Ice Card Styling */
/* Ice Card Styling */
.ice-card {
  background-color: #3a3a4f;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90vw; /* Fill most of the screen width */
  text-align: left; /* Align text content within its container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 10px auto; /* Center horizontally */
  display: flex; /* Flexbox for layout */
  align-items: center; /* Vertically align items */
  gap: 15px; /* Space between image and content */
  box-sizing: border-box;
  user-select: none;
}

/* Team Avatar Styling */
.team-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Circular image */
  object-fit: cover; /* Ensure image scales properly */
  margin-right: 3em; /* Additional space between image and content */
  user-select: none;
}

/* Content Styling */
.ice-card-content {
  display: flex;
  flex-direction: column; /* Stack text vertically */
  justify-content: center; /* Vertically center text relative to the image */
  text-align: left; /* Align text to the left inside the content block */
  flex: 1; /* Allow content to take up remaining space */
  user-select: none;
}

.week-navigation {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Adjust alignment (center horizontally if needed) */
  gap: 10px; /* Add spacing between the elements */
}

.week-navigation button {
  padding: 10px 20px; /* Adds spacing around the text */
  font-size: 2em; /* Adjusts text size */
  cursor: pointer; /* Changes cursor to pointer on hover */
  background-color: #282c34;
  color: white; /* White text for visibility */
  border-radius: 10px; /* Rounded corners for modern look */
  transition: filter 0.3s ease, transform 0.2s ease; /* Adds hover and click effects */
  border: none;
}

.week-navigation button:hover {
  filter: brightness(1.2); /* Increases brightness on hover */
}

.week-navigation button:active {
  transform: scale(0.95); /* Adds a slight press effect */
}

/* Footer.css */
.footer {
  position: fixed; /* Keeps it at the bottom */
  bottom: 0;
  width: 100%;
  color: white;
  background: #282c34;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.linkText {
  color: #CF9FFF;
}

.ice-cards-container {
  padding-bottom: 3em;
}